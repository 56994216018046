import React, { useState, useEffect } from 'react';
import { Layout, Table, Tag, Button, Dropdown, Menu, Typography } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { getAssignments } from '../../../api';
import { useNavigate } from 'react-router-dom';
import './CandidateApplications.css';

const { Content } = Layout;
const { Title } = Typography;

const CandidateApplications = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAssignments();
        const assignments = response.data.map((assignment) => ({
          ...assignment,
          key: assignment.id,
        }));
        setDataSource(assignments);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching assignments:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleViewDetails = (record) => {
    navigate(`/dashboard/candidate-detail`, { state: { candidate: record } });
  };

  const columns = [
    {
      title: 'Candidate Name',
      dataIndex: ['employee', 'fullName'],
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: ['employee', 'email'],
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: ['employee', 'phone'],
      key: 'phone',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'pending' ? 'orange' : status === 'accepted' ? 'green' : 'blue'}>
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1" onClick={() => handleViewDetails(record)}>
                View Details
              </Menu.Item>
              <Menu.Item key="2" onClick={() => console.log('Edit Candidate', record)}>
                Edit
              </Menu.Item>
              <Menu.Item key="3" onClick={() => console.log('Delete Candidate', record)}>
                Delete
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <Button icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Content
      className="site-layout-background"
      style={{
        margin: '24px 16px',
        padding: 24,
        minHeight: 280,
        backgroundColor: '#fff',
      }}
    >
      <Title level={3}>Candidate Applications</Title>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={loading}
        pagination={{ pageSize: 10 }}
      />
    </Content>
  );
};

export default CandidateApplications;
