import React, { useEffect, useState } from 'react';
import { Card, Typography, Row, Col, Tag, Divider, Tabs, Table, Dropdown, Menu, Input, message } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import {getJobAssignments} from '../../../api';
import './JobDetails.css';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const JobDetails = () => {
  const location = useLocation();
  const { job } = location.state;
  const navigate = useNavigate();
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCandidates = async () => {
      setLoading(true);
      try {
        const response = await getJobAssignments(job._id);
        const jobCandidates = response.data
        setCandidates(jobCandidates);
      } catch (error) {
        message.error('Failed to fetch candidates');
      } finally {
        setLoading(false);
      }
    };

    fetchCandidates();
  }, [job._id]);

  const columns = [
    {
      title: 'Employee Name',
      dataIndex: ['employee', 'fullName'],
      key: 'employeeName',
    },
    {
      title: 'Email',
      dataIndex: ['employee', 'email'],
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: ['employee', 'phone'],
      key: 'phone',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'pending' ? 'yellow' : status === 'offer-sent' ? 'green' : 'blue'}>
          {status}
        </Tag>
      ),
    },
    {
      title: 'View',
      key: 'view',
      render: (text, record) => <a onClick={() => handleViewDetails(record)}>Details</a>,
    },
    {
      title: 'Action',
      key: 'action',
      render: () => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">Edit</Menu.Item>
              <Menu.Item key="2">Delete</Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <EllipsisOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
        </Dropdown>
      ),
    },
  ];

  const handleViewDetails = (record) => {
    navigate('/dashboard/candidate-detail', { state: { candidate: record } });
  };

  return (
    <div className="job-details-container">
      <Card>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Details" key="1">
            <Row justify="space-between">
              <Col>
                <Title level={3}>{job.title}</Title>
                <Text>{job.employer}</Text>
                <br />
                <Text>{`${job.address.street}, ${job.address.city}, ${job.address.state} ${job.address.zipCode}`}</Text>
              </Col>
              <Col>
                <Tag color={job.status === 'Published' ? 'blue' : 'orange'}>{job.status}</Tag>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={6}>
                <Text strong>Start Date:</Text> {new Date(job.startDate).toLocaleDateString()}
              </Col>
              <Col span={6}>
                <Text strong>End Date:</Text> {new Date(job.endDate).toLocaleDateString()}
              </Col>
              <Col span={6}>
                <Text strong>Shift:</Text> {job.shift}
              </Col>
              <Col span={6}>
                <Text strong>Length:</Text> {job.length}
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={6}>
                <Text strong>Type:</Text> {job.type}
              </Col>
              <Col span={6}>
                <Text strong>Unit:</Text> {job.unit}
              </Col>
              <Col span={6}>
                <Text strong>Salary Range:</Text> {`$${job.salary.base.toLocaleString()} /hr`}
              </Col>
              <Col span={6}>
                <Text strong>Float:</Text> {job.isFloat ? 'Yes' : 'No'}
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={12}>
                <Text strong>Number of Positions:</Text> {job.numberOfOpenings}
              </Col>
              <Col span={12}>
                <Text strong>Manager:</Text> {job.managerName}
              </Col>
            </Row>
            <Divider />
            <Title level={5}>Job Description</Title>
            <Text>{job.duties}</Text>
            <Divider />
            <Title level={5}>Roles and Responsibilities</Title>
            <Text>{job.roles}</Text>
            <Divider />
            <Title level={5}>Documents</Title>
            <Text>{job.mandatoryDocuments.join(', ') || 'None'}</Text>
          </TabPane>
          <TabPane tab={`Candidates (${candidates.length})`} key="2">
            <Input.Search placeholder="Search candidates" style={{ marginBottom: 16 }} />
            {console.log("candidates",candidates)}
            <Table dataSource={candidates} columns={columns} loading={loading} pagination={{ pageSize: 10 }} />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default JobDetails;
