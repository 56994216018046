import React, { useState, useEffect } from 'react';
import { Layout, Table, Tag, Button, Dropdown, Menu, Typography } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { getOngoingAssignments } from '../../../api';
import { useNavigate } from 'react-router-dom';
import './OngoingEngagements.css';

const { Content } = Layout;
const { Title } = Typography;

const OngoingEngagements = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getOngoingAssignments();
        const assignments = response.data.map((assignment) => ({
          ...assignment,
          key: assignment.id,
        }));
        setDataSource(assignments);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching ongoing engagements:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleViewDetails = (record) => {
    navigate(`/dashboard/job-details`, { state: { job: record } });
  };

  const columns = [
    {
      title: 'Assignment Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Candidate Name',
      dataIndex: ['employee', 'fullName'],
      key: 'employeeName',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate) => new Date(startDate).toLocaleDateString(),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (endDate) => endDate ? new Date(endDate).toLocaleDateString() : 'Ongoing',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'ongoing' ? 'green' : 'blue'}>
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1" onClick={() => handleViewDetails(record)}>
                View Details
              </Menu.Item>
              <Menu.Item key="2" onClick={() => console.log('Edit Engagement', record)}>
                Edit
              </Menu.Item>
              <Menu.Item key="3" onClick={() => console.log('End Engagement', record)}>
                End Engagement
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <Button icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Content
      className="site-layout-background"
      style={{
        margin: '24px 16px',
        padding: 24,
        minHeight: 280,
        backgroundColor: '#fff',
      }}
    >
      <Title level={3}>Ongoing Engagements</Title>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={loading}
        pagination={{ pageSize: 10 }}
      />
    </Content>
  );
};

export default OngoingEngagements;
