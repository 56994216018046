import React, { useState } from 'react';
import { Form, Input, Button, Typography, message, Select } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../AuthProvider';
import './auth.css';

const { Title } = Typography;

const { Option } = Select;
const SignUpScreen = () => {
  const [form] = Form.useForm();
  const [tenantType, setTenantType] = useState('employer');
  const navigate = useNavigate();
  const { signup, loginWithGoogle } = useAuth();

  const onFinish = async (values) => {
    try {
      const tenantType = values.tenantType || 'employee'; // Ensure tenantType is set correctly
      await signup(values.email, values.password, tenantType);
      navigate(tenantType === 'agency' ? '/agency' : '/dashboard'); // Navigate based on tenantType
    } catch (error) {
      // Handle different error cases
      switch (error.code) {
        case 'auth/email-already-in-use':
          message.error('This email is already in use. Please use a different email.');
          break;
        case 'auth/invalid-email':
          message.error('The email address is not valid.');
          break;
        case 'auth/weak-password':
          message.error('The password is too weak. Please use a stronger password.');
          break;
        case 'auth/operation-not-allowed':
          message.error('Email/password accounts are not enabled. Please contact support.');
          break;
        case 'auth/too-many-requests':
          message.error('Too many requests. Please try again later.');
          break;
        default:
          message.error('Failed to sign up. Please try again.');
          console.error('Sign-up error:', error); // Log the error for debugging purposes
      }
    }
  };


  const validateConfirmPassword = (_, value) => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject(new Error('The two passwords do not match!'));
    }
    return Promise.resolve();
  };

  return (
    <div className="auth-container">
      <div className="auth-background"></div>
      <div className="auth-form-container">
        <Title level={2}>Sign Up</Title>
        <Form form={form} name="signup" onFinish={onFinish} layout="vertical">
          <Form.Item name="tenantType" label="Signup as" initialValue="employer">
            <Select
              onChange={setTenantType}
              placeholder="Select your account type"
              size="large"
            >
              <Option value="employer">
                Healthcare Facility
              </Option>
              {/* <Option value="agency">
                <UserOutlined style={{ marginRight: 8 }} />
                Agency
              </Option> */}
            </Select>
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Please enter your email!' }]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please enter your password!' }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Please confirm your password!' },
              { validator: validateConfirmPassword }
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="auth-form-button">
              Sign Up
            </Button>
          </Form.Item>
        </Form>
        <div className="auth-switch" style={{ marginTop: '10px' }}>
          Already have an account? <Link to="/login" style={{ color: '#006971', fontWeight: 'bold' }}>Sign In</Link>
        </div>
      </div>
    </div>
  );
};

export default SignUpScreen;
